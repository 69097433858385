import React, { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from './auth-context.js';

// Crear el contexto
export const UserProfileContext = createContext(null);

// Proveedor del contexto del perfil del usuario
export const UserProfileProvider = ({ children }) => {
    const { user } = useContext(AuthContext); // Obtener usuario del contexto de autenticación
    const [userData, setUserData] = useState(null); // Estado para la información del usuario
    const [folios, setFolios] = useState([]); // Estado para los folios y tickets reservados
    const [loading, setLoading] = useState(true); // Estado de carga
    const [error, setError] = useState(null); // Estado de error
    const [ticketLimit, setTicketLimit] = useState(100); // Estado para el límite de tickets (predeterminado)
    

    useEffect(() => {
        if (user) {
            const fetchData = async () => {
                try {
                    const token = localStorage.getItem('authToken');
                    
                    // Obtener la información del perfil del usuario
                    const userResponse = await axios.get('https://luckygarage-api.onrender.com/api/usuarios/perfil', {
                        headers: { Authorization: `Bearer ${token}` }
                    });
                    const userData = userResponse.data.data;
                    setUserData(userData);

                    // Establecer el límite de tickets desde la respuesta del perfil
                    setTicketLimit(userData.limiteTickets);

                    // Obtener los tickets reservados agrupados por userId, con fechaReserva y fechaExpiracionReserva
                    const ticketsResponse = await axios.get('https://luckygarage-api.onrender.com/api/tickets/tickets/userId', {
                        headers: { Authorization: `Bearer ${token}` }
                    });

                    const ticketsData = ticketsResponse.data.data.map(ticket => ({
                        ...ticket,
                        fechaReserva: ticket.fechaReserva, // Asegurarse de que las fechas estén disponibles
                        fechaExpiracionReserva: ticket.fechaExpiracionReserva
                    }));

                    setFolios(ticketsData || []); // Asegúrate de que folios sea un array

                    setLoading(false);
                } catch (err) {
                    console.error('Error fetching data:', err);
                    setError(err.response?.data?.message || 'Error al cargar datos');
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [user]);

    // Contar el número de tickets reservados
    const totalTicketsReservados = folios.length;

    return (
        <UserProfileContext.Provider value={{ userData, folios, totalTicketsReservados, ticketLimit, loading, error }}>
            {children}
        </UserProfileContext.Provider>
    );
};