import React from 'react';
import styles from './preguntasfrecuentes.module.css';
import Accordion from 'react-bootstrap/esm/Accordion.js';
import { Link } from 'react-router-dom'; 
import "bootstrap/dist/css/bootstrap.min.css";

function PreguntasFrecuentes() {
  return (
    <div className={styles["faq-section"]}>
      <h2>Preguntas Frecuentes</h2>
      <p>Estas son las preguntas que más frecuentes. Si tienen más dudas, <Link to='/Contacto' className={styles.link}>contáctenos</Link>.</p>
      <br></br>
      
      <Accordion flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header>¿Cómo sé que mi boleto es válido?</Accordion.Header>
          <Accordion.Body>
          Una vez que hayas completado tu pago, te enviaremos un correo electrónico de confirmación con los detalles de tu boleto. Este correo electrónico sirve como prueba de compra y garantiza que tu boleto es válido. Si no recibes el correo dentro de unos minutos, revisa tu carpeta de spam o contacta a nuestro equipo de soporte para obtener ayuda.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="1">
          <Accordion.Header>¿Cómo y cuándo se eligen los ganadores?</Accordion.Header>
          <Accordion.Body>
          El ganador será seleccionado junto con el sorteo de la Lotería Nacional, para garantizar la legitimidad e integridad del proceso. El participante cuyos números coincidan con las últimas cifras del primer premio ganador de la Lotería Nacional será el ganador. La fecha es tentativa.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>¿Dónde se anunciarán los ganadores?</Accordion.Header>
          <Accordion.Body>
          Los ganadores se anunciarán en nuestras redes sociales, donde ofreceremos una prueba en vivo para garantizar la legitimidad e integridad de la lotería. Además, el ganador recibirá un correo electrónico con el que nos pondremos en contacto para coordinar la entrega del premio.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="3">
          <Accordion.Header>¿Qué pasa si no podemos contactar al ganador?</Accordion.Header>
          <Accordion.Body>
          Si no logramos contactar al ganador en un plazo de 30 días o el ganador no se comunica con nosotros, la lotería se reiniciará y los boletos volverán a estar disponibles.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="4">
          <Accordion.Header>¿Cuántos boletos puedo comprar?</Accordion.Header>
          <Accordion.Body>
          Puedes comprar la cantidad de boletos que desees, sin ningún límite. Cada boleto adicional aumenta tus probabilidades de ganar.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="5">
          <Accordion.Header>¿Está disponible en cualquier parte de México?</Accordion.Header>
          <Accordion.Body>
          Sí, la lotería está disponible para participantes que se encuentren en cualquier parte de la República Mexicana.
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="6">
          <Accordion.Header>¿Qué formas de pago aceptan?</Accordion.Header>
          <Accordion.Body>
          Actualmente, solo aceptamos depósitos directos a nuestras cuentas bancarias. Los detalles del depósito o transferencia están en la sección de método de pago.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default PreguntasFrecuentes;
