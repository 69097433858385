import React from 'react';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import carImage1 from '../../assets/asientoTrasero.jpg';
import carImage2 from '../../assets/detalleBotones.jpg';
import carImage3 from '../../assets/cajuelaAbierta.jpg';
import carImage4 from '../../assets/frontal.jpg';
import carImage6 from '../../assets/pantallaPrendida.jpg';
import carImage7 from '../../assets/volante.jpg';
import carImage9 from '../../assets/rinTrasero.jpg';

import styles from './collage.module.css'; // Import the CSS file

export default function MyComponent() {
  /*const images = [
    carImage1,
    carImage2,
    carImage3,
    carImage4,
    carImage6,
    carImage7,
    carImage9
  ];*/

  return (
    <PhotoProvider>
      <div className={styles["image-grid"]}>
        {/* Primera columna - imagen pequeña */}
        <PhotoView src={carImage1}>
          <img src={carImage1} className={styles["first-image"]} />
        </PhotoView>

        {/* Segunda columna - imagen media */}
        <PhotoView src={carImage2}>
          <img src={carImage2} className={styles["second-image"]} />
        </PhotoView>

        {/* Tercera columna - imagen alta (ocupa 2 filas) */}
        <PhotoView src={carImage3}>
          <img src={carImage3} className={styles["third-image"]} />
        </PhotoView>

        {/* Cuarta columna - imagen media */}
        <PhotoView src={carImage4}>
          <img src={carImage4} className={styles["fourth-image"]} />
        </PhotoView>

        {/* Quinta columna - imagen pequeña */}
        <PhotoView src={carImage6}>
          <img src={carImage6} className={styles["fifth-image"]} />
        </PhotoView>

        {/* Segunda fila - Primera columna - imagen media */}
        <PhotoView src={carImage7}>
          <img src={carImage7} className={styles["sixth-image"]} />
        </PhotoView>

        {/* Segunda fila - Segunda columna - imagen media */}
        <PhotoView src={carImage9}>
          <img src={carImage9} className={styles["seventh-image"]} />
        </PhotoView>
      </div>
    </PhotoProvider>
  );
}
