import React, { createContext, useState, useEffect } from 'react';
import useFetch from '../hooks/useFetch.js'; // Ajusta el path si es necesario

export const TicketContext = createContext(null);

function TicketProvider({ children }) {
  const { data, loading, error, refetch } = useFetch('https://luckygarage-api.onrender.com/api/tickets/disponibles');
  const [numTickets, setNumTickets] = useState(0);
  const [availableTickets, setAvailableTickets] = useState([]);

  useEffect(() => {
    if (data && Array.isArray(data)) {
      setAvailableTickets(data);
      setNumTickets(data.length);
    } else if (data && data.data && Array.isArray(data.data)) {
      setAvailableTickets(data.data);
      setNumTickets(data.data.length);
    }
  }, [data]);

  // Exponer la función refetch para que pueda ser llamada desde otros componentes
  return (
    <TicketContext.Provider value={{ data: availableTickets, numTickets, loading, error, refetch }}>
      {children}
    </TicketContext.Provider>
  );
}

export default TicketProvider;