import React, { createContext, useState, useEffect, useContext } from 'react';
import { TicketContext } from './ticket-context.js';
import { AuthContext } from './auth-context.js'; // Asegúrate de que la ruta sea correcta
import axios from 'axios';

export const ShopContext = createContext(null);

const getDefaultCart = (numTickets) => {
  let cart = {};
  if (numTickets) {
    for (let i = 1; i <= numTickets; i++) {
      cart[i] = 0;
    }
  }
  return cart;
};

function ShopContextProvider({ children }) {
  const { numTickets, loading: ticketLoading, error: ticketError } = useContext(TicketContext);
  const { user } = useContext(AuthContext); // Acceder al contexto de autenticación
  const [cartItems, setCartItems] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(''); // State for message box
  const [messageVisible, setMessageVisible] = useState(false); // State for visibility

  useEffect(() => {
    if (numTickets !== null && numTickets !== undefined) {
      setCartItems(getDefaultCart(numTickets));
      setIsLoading(false);
    }
  }, [numTickets]);

  useEffect(() => {
    if (ticketLoading) {
      setIsLoading(true);
    }
    if (ticketError) {
      setError(ticketError);
      setIsLoading(false);
    }
  }, [ticketLoading, ticketError]);

  const addToCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: (prev[itemId] || 0) + 1 }));
  };

  const removeFromCart = (itemId) => {
    setCartItems((prev) => ({ ...prev, [itemId]: Math.max((prev[itemId] || 0) - 1, 0) }));
  };

  const toggleCartItem = (itemId) => {
    if ((cartItems[itemId] || 0) > 0) {
      removeFromCart(itemId);
    } else {
      addToCart(itemId);
    }
  };

  const reserveTickets = async (totalQuantity, folioReserva) => {
    const ticketNumbers = Object.keys(cartItems).filter((key) => cartItems[key] !== 0);
    const token = localStorage.getItem('authToken');
    
    if (!token) {
      setMessage('Debes iniciar sesión para reservar boletos.');
      setMessageVisible(true);
      return;
    }

    if (!user || !user.email) {
      setMessage('No se pudo obtener la información del usuario.');
      setMessageVisible(true);
      return;
    }

    try {
      const response = await axios.post('https://luckygarage-api.onrender.com/api/tickets/reservar', {
        ticketNumbers,
        reservadoPor: user._id,  // Usa el ID del usuario
        folioReserva,
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      const data = response.data;
      if (response.status === 200) {
        setMessage(`Boletos reservados con éxito. Folio de reserva: ${data.folioReserva}. Recuerda realizar la transferencia bancaria a través de nuestro WhatsApp. Tienes 12 horas para completar el pago; de no hacerlo, el boleto estará nuevamente disponible.`);
        setMessageVisible(true);
        setCartItems(getDefaultCart(numTickets)); // Limpia el carrito después de la reserva
        setTimeout(() => {
          window.location.reload();
        }, 30000);
      }
    } catch (error) {
      // Mostrar el mensaje de error recibido del backend si está disponible
      if (error.response && error.response.data && error.response.data.message) {
        setMessage(`Error al reservar los boletos: ${error.response.data.message}`);
      } else {
        setMessage('Hubo un error al realizar la reserva.');
      }
      setMessageVisible(true);
    }
  };

  if (isLoading) return <p>Loading cart...</p>;
  if (error) return <p>Error loading cart: {error.message}</p>;

  const contextValue = {
    cartItems,
    addToCart,
    removeFromCart,
    toggleCartItem,
    reserveTickets,
    message,          // Expose message state
    messageVisible,   // Expose visibility state
    setMessageVisible, // Function to change visibility
    setMessage
  };

  return (
    <ShopContext.Provider value={contextValue}>
      {children}
    </ShopContext.Provider>
  );
}

export default ShopContextProvider;
