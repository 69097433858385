import React, { useContext, useState, useEffect } from 'react';
import { UserProfileContext } from '../../context/perfil-context.js';
import styles from './usuario.module.css';

const UserProfile = () => {
  const { userData, folios, totalTicketsReservados, ticketLimit, loading, error } = useContext(UserProfileContext);

  // Function to calculate time remaining until expiration
  const calculateCountdown = (expirationDate) => {
    const now = new Date().getTime();
    const expirationTime = new Date(expirationDate).getTime();
    const timeRemaining = expirationTime - now;

    if (timeRemaining <= 0) {
      return {
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const hours = Math.floor(timeRemaining / (1000 * 60 * 60));
    const minutes = Math.floor((timeRemaining % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeRemaining % (1000 * 60)) / 1000);

    return { hours, minutes, seconds };
  };

  const [countdowns, setCountdowns] = useState({});

  useEffect(() => {
    const intervalId = setInterval(() => {
      const newCountdowns = {};
      folios.forEach(folio => {
        if (folio.length > 0) {
          const fechaExpiracion = folio[0].fechaExpiracionReserva;
          newCountdowns[folio[0].folioReserva] = calculateCountdown(fechaExpiracion);
        }
      });
      setCountdowns(newCountdowns);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [folios]);

  if (loading) return <p>Cargando...</p>;
  if (error) return <p>Error: {error}</p>;

  const ticketsDisponibles = ticketLimit - totalTicketsReservados;

  const ticketsPorFolio = folios.reduce((acc, ticket) => {
    if (!acc[ticket.folioReserva]) {
      acc[ticket.folioReserva] = [];
    }
    acc[ticket.folioReserva].push(ticket);
    return acc;
  }, {});

  // Filtrar los tickets reservados y pagados
  const ticketsReservados = Object.keys(ticketsPorFolio).reduce((acc, folio) => {
    const reservedTickets = ticketsPorFolio[folio].filter(ticket => ticket.estado === 'reservado');
    if (reservedTickets.length > 0) acc[folio] = reservedTickets;
    return acc;
  }, {});

  const ticketsPagados = Object.keys(ticketsPorFolio).reduce((acc, folio) => {
    const paidTickets = ticketsPorFolio[folio].filter(ticket => ticket.estado === 'pagado');
    if (paidTickets.length > 0) acc[folio] = paidTickets;
    return acc;
  }, {});

  return (
    <div className={styles.container}>
      <div className={styles.profileHeader}>
        <h2>Perfil</h2>
      </div>
      {userData ? (
        <div className={styles.profileInfo}>
          <p><strong>Nombre:</strong> {userData.nombre}</p>
          <p><strong>Email:</strong> {userData.email}</p>
          <p><strong>Teléfono:</strong> {userData.telefono || 'No disponible'}</p>
        </div>
      ) : (
        <div className={styles.profileInfo}>
          <p>No se encontraron datos del usuario.</p>
        </div>
      )}

      <div className={styles.reservaciones}>
        <div className={styles.reservacionesHeader}>
          <h2>Tickets Reservados</h2>
        </div>
        {Object.keys(ticketsReservados).length > 0 ? (
          Object.keys(ticketsReservados).map(folio => (
            <div key={folio} className={styles.folioContainer}>
              <h4><strong>Folio:</strong> {folio}</h4>
              {ticketsReservados[folio].length > 0 ? (
                <>
                  <p>
                    <strong>Expira en:</strong> {calculateCountdown(ticketsReservados[folio][0].fechaExpiracionReserva).hours}h&nbsp;
                    {calculateCountdown(ticketsReservados[folio][0].fechaExpiracionReserva).minutes}m&nbsp;
                    {calculateCountdown(ticketsReservados[folio][0].fechaExpiracionReserva).seconds}s&nbsp;
                  </p>
                </>
              ) : (
                <p>Cargando countdown...</p>
              )}
              <ul className={styles.ticketsList}>
                {ticketsReservados[folio].map(ticket => (
                  <li key={ticket._id}>
                    <p><strong>Número de Boleto:</strong> {ticket.numeroTicket.toString().padStart(5, '0')}</p>
                    <p><strong>Oportunidades adicionales:</strong> {(ticket.numeroTicket + 20000).toLocaleString()} y {(ticket.numeroTicket + 40000).toLocaleString()}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))
        ) : (
          <div className={styles.noTicketsMessage}>
            <p>No tienes tickets reservados.</p>
          </div>
        )}
      </div>

      {/* Sección de Tickets Pagados */}
      <div className={styles.reservaciones}>
        <div className={styles.reservacionesHeader}>
          <h2>Tickets Pagados</h2>
        </div>
        {Object.keys(ticketsPagados).length > 0 ? (
          <ul className={styles.ticketsList}>
            {Object.keys(ticketsPagados).map(folio => (
              ticketsPagados[folio].map(ticket => (
                <li key={ticket._id}>
                  <p><strong>Número de Boleto:</strong> {ticket.numeroTicket.toString().padStart(5, '0')}</p>
                  <p><strong>Oportunidades adicionales:</strong> {(ticket.numeroTicket + 20000).toLocaleString()} y {(ticket.numeroTicket + 40000).toLocaleString()}</p>
                </li>
              ))
            ))}
          </ul>
        ) : (
          <div className={styles.noTicketsMessage}>
            <p>No tienes tickets pagados.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
