import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetch = (url) => {
    const [data, setData] = useState([]); // Initialize with an empty array
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get(url);
                // Directly set data as an array
                console.log('fetched data', response.data);
                setData(response.data); // response.data is an array
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [url]);

    // Calculate numTickets based on the length of the array
    const numTickets = data.length;

    const reFetch = async () => {
        setLoading(true);
        try {
            const response = await axios.get(url);
            setData(response.data); // Update with new data
        } catch (err) {
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    return { data, loading, error, reFetch, numTickets };
};

export default useFetch;
