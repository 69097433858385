import React, { createContext, useState, useEffect } from 'react';
import axios from 'axios';

// Crear el contexto
export const AuthContext = createContext(null);

// Proveedor del contexto de autenticación
export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null); // Estado del usuario
    const [loading, setLoading] = useState(true); // Estado de carga
    const [error, setError] = useState(null); // Estado de error

    // Función para iniciar sesión
    const login = async (email, password) => {
        try {
            const response = await axios.post('https://luckygarage-api.onrender.com/api/auth/login', { email, password });
            const { token, user } = response.data;
    
            // Almacenar el token y el tipo de usuario en localStorage
            localStorage.setItem('authToken', token);
            localStorage.setItem('isAdmin', user.tipo === 'admin' ? 'true' : 'false');
    
            setUser(user); // Establecer el usuario
            setError(null); // Limpiar error
    
            // Establecer el token para futuras solicitudes
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } catch (err) {
            setError('Error al iniciar sesión. Tu cuenta no ha sido verificada.');
            throw err; // Lanza el error para que se maneje en el componente de Login
        }
    };

    // Función para cerrar sesión
    const logout = async () => {
        try {
            await axios.post('https://luckygarage-api.onrender.com/api/auth/logout');
            setUser(null); // Limpiar usuario
            
            // Eliminar el token y la información de admin de localStorage
            localStorage.removeItem('authToken');
            localStorage.removeItem('isAdmin');
            
            // Eliminar el token de las cabeceras de axios
            delete axios.defaults.headers.common['Authorization'];
        } catch (err) {
            setError('Error al cerrar sesión.');
        }
    };

    // Cargar el estado del usuario al montar el componente
    useEffect(() => {
        const fetchUser = async () => {
            const token = localStorage.getItem('authToken');
            const isAdmin = localStorage.getItem('isAdmin') === 'true';
    
            if (token) {
                try {
                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    const response = await axios.get('https://luckygarage-api.onrender.com/api/auth/me');
                    setUser(response.data.user);
                } catch (err) {
                    console.error('Token inválido o expirado. Cerrando sesión.');
                    setUser(null);
                    logout(); // Remover token si es inválido o expirado
                }
            }
    
            setLoading(false);
        };
    
        fetchUser();
    }, []);

    // Función para manejar la expiración del token (si es necesario)
    const refreshToken = async () => {
        try {
            const response = await axios.post('https://luckygarage-api.onrender.com/api/auth/refresh');
            const { token } = response.data;
            localStorage.setItem('authToken', token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } catch (err) {
            console.error('Error al refrescar el token.');
            logout(); // Cerrar sesión si no se puede refrescar el token
        }
    };

    return (
        <AuthContext.Provider value={{ user, loading, error, login, logout, refreshToken }}>
            {children}
        </AuthContext.Provider>
    );
};