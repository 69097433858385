import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Importa useNavigate para redirigir
import Countdown from '../Countdown/countdown.js';
import PremioCarousel from '../PremioCarousel/premioCarousel.js';
import Tickets from '../Tickets/tickets.js';
import styles from './inicio.module.css';
import VideoComponent from './video.js';
import { TicketContext } from '../../context/ticket-context.js';
import axios from 'axios';
import { FaSearch } from 'react-icons/fa';
import luckyGif from '../../assets/slotmachine.gif'; 
import { ShopContext } from '../../context/shop-context.js';
import stylesLucky from '../ComprarBoletos/comprarboletos.module.css';
import Modal from '../CarroCompras/modal.js';

function Inicio() {
  const { numTickets, loading, error, data: availableTickets = [] } = useContext(TicketContext);


  const navigate = useNavigate(); // Hook para la navegación
  const [inputValue, setInputValue] = useState('');
  const [ticket, setTicket] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [luckyModalVisible, setLuckyModalVisible] = useState(false);
  const [luckyTickets, setLuckyTickets] = useState([]);
  const [showLuckyGif, setShowLuckyGif] = useState(false); // Para el GIF de la máquina tragamonedas
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [luckyTicketCount, setLuckyTicketCount] = useState(1); 
  // const [luckyAddedTickets, setLuckyAddedTickets] = useState([]);

   // New state for WhatsApp modal and user name
  const [whatsappModalVisible, setWhatsappModalVisible] = useState(false);
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState(''); 

  // State for the search input
  const [searchValue, setSearchValue] = useState('');

  // Access cartItems, addToCart, and removeFromCart from ShopContext
  const { cartItems } = useContext(ShopContext);

  // Funciones para manejar la redirección
  const handleReserveTickets = () => {
    navigate('/ComprarBoletos'); // Redirige a la página de ComprarBoletos
  };

  const handleGoToCart = () => {
    navigate('/CarroCompras'); // Redirige a la página de CarroCompras
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`https://luckygarage-api.onrender.com/api/tickets/search/${searchValue}`); 
      // Usa searchValue para la búsqueda
      setTicket(response.data);
      setErrorMessage(''); 
    } catch (error) {
      setErrorMessage('Ticket not found');
      setTicket(null);
    }
  };  

  const handleLuckyTickets = () => {
    // Disable the button once it's clicked
    setIsButtonClicked(false);

    // Validate if luckyTicketCount exceeds the limit
    if (luckyTicketCount > 100) {
      setErrorMessage("No puedes agregar más de 100 boletos.");
      setIsButtonClicked(false); // Re-enable the button
      return;
    }

    // Filter only available tickets and exclude those already in the cart
    let availableTicketNumbers = availableTickets
      .filter((ticket) => ticket.estado === 'disponible' && !cartItems[ticket.numeroTicket])
      .map((ticket) => ticket.numeroTicket);

    if (availableTicketNumbers.length === 0) {
      setErrorMessage("No hay boletos disponibles para agregar o ya están en tu carrito.");
      setIsButtonClicked(false); // Re-enable the button
      return;
    }

    // Ensure the number of tickets to add doesn't exceed available tickets
    if (luckyTicketCount > availableTicketNumbers.length) {
      setErrorMessage(
        `Solo hay ${availableTicketNumbers.length} boletos disponibles. No puedes agregar más de esa cantidad.`
      );
      setLuckyTicketCount(availableTicketNumbers.length);
      setIsButtonClicked(false); // Re-enable the button
      return;
    }

    let ticketsToAdd = [];
    for (let i = 0; i < luckyTicketCount; i++) {
      const randomIndex = Math.floor(Math.random() * availableTicketNumbers.length);
      const randomTicketNumber = availableTicketNumbers[randomIndex];
      ticketsToAdd.push(randomTicketNumber);
  
      // Eliminar el boleto seleccionado para evitar duplicados
      availableTicketNumbers = availableTicketNumbers.filter(
        (ticket) => ticket !== randomTicketNumber
      );
    }

    // Add the selected tickets to the cart
    ticketsToAdd.forEach((ticket) => {
      if (cartItems[ticket]) {
        cartItems[ticket] += 1; // Increment quantity if ticket is already in the cart
      } else {
        cartItems[ticket] = 1; // Add new ticket to the cart
      }
    });
  
    setLuckyTickets(ticketsToAdd);
    setShowLuckyGif(true);
  
    // Cerrar el modal después de un tiempo
    setTimeout(() => {
      setShowLuckyGif(false);
      setLuckyModalVisible(false);
    }, 3000);
  };  

  // Function to close the lucky modal
  const handleCloseLuckyModal = () => {
    setLuckyModalVisible(false);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
  
    // If the value is empty, allow it temporarily
    if (value === '') {
      setLuckyTicketCount(''); // Allow empty input
      setErrorMessage(''); // Clear any error messages while typing
      return;
    }
  
    // Parse the value to an integer
    const parsedValue = parseInt(value, 10);
  
    // If the parsed value is NaN (invalid number), exit early
    if (isNaN(parsedValue)) {
      return;
    }
  
    // Validate the input value and update the state accordingly
    if (parsedValue > 100) {
      setErrorMessage('No puedes agregar más de 100 boletos.');
      setLuckyTicketCount(100); // Set the max value to 100
    } else if (parsedValue < 1) {
      setErrorMessage('El número mínimo es 1.');
      setLuckyTicketCount(1); // Set the minimum value to 1
    } else {
      setErrorMessage(''); // Clear error messages if the input is valid
      setLuckyTicketCount(parsedValue); // Update the state with the valid number
    }
  };  

  // Timer to remove the lucky message after 2 minutes
  useEffect(() => {
    if (luckyTickets.length > 0) {
      const timer = setTimeout(() => {
        setLuckyTickets([]);
      }, 120000); // 2 minutes in milliseconds

      return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
    }
  }, [luckyTickets]);

  if (loading) {
    return <div>Loading tickets...</div>;
  }

  if (error) {
    return <div>Error fetching tickets: {error.message}</div>;
  }

  if (availableTickets.length === 0) {
    return <div>No tickets available</div>;
  }

  if (loading) return <p>Loading tickets...</p>;
  if (error) return <p>Error loading tickets: {error.message}</p>;

  // Filter tickets based on searchValue
  const filteredTickets = searchValue
    ? availableTickets.filter((ticket) =>
        ticket.numeroTicket.toString().includes(searchValue)
      )
    : availableTickets;

  // Sort filtered tickets by numeroTicket in ascending order
  //const sortedTickets = filteredTickets.sort((a, b) => a.numeroTicket - b.numeroTicket);

  // Get the list of selected tickets from cartItems
  const selectedTickets = Object.keys(cartItems)
    .filter((ticketId) => cartItems[ticketId] > 0)
    .map(Number); // Convert keys to numbers

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!userName.trim() || !userPhone.trim()) {
      alert('Por favor ingresa tu nombre y teléfono.');
      return;
    }
  
    const data = {
      name: userName,
      phone: userPhone,
      tickets: selectedTickets,
    };
  
    try {
      // Enviar los datos al backend
      const response = await fetch('https://luckygarage-api.onrender.com/api/invitado/interest', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) throw new Error('Error al guardar los datos');
  
      // Redirigir a WhatsApp con el mensaje
      const message = selectedTickets.length > 0
        ? `Hola, me llamo ${userName} (${userPhone}) y me gustaría reservar los siguientes boletos: ${selectedTickets.map(ticket => ticket.toString().padStart(5, '0')).join(', ')}. Me podrias pasar más información sobre el pago.`
        : `Hola, me llamo ${userName} (${userPhone}) y me gustaría saber más sobre los boletos disponibles.`;
  
      const whatsappLink = `https://wa.me/523335027497?text=${encodeURIComponent(message)}`;
      window.open(whatsappLink, '_blank');
  
      // Cerrar modal
      setWhatsappModalVisible(false);
  
    } catch (error) {
      console.error('Error al enviar datos:', error);
      alert('Ocurrió un error al guardar los datos.');
    }
  };

  const handleWhatsAppClick = () => {
  // Ensure user inputs are valid
  if (!userName.trim() || !userPhone.trim()) {
    alert('Por favor ingresa tu nombre y teléfono.');
    return;
  }

  // Generate WhatsApp message based on selected tickets
  const message = selectedTickets.length > 0
    ? `Hola, me llamo ${userName} (${userPhone}) y me gustaría reservar los siguientes boletos: ${selectedTickets.map(ticket => ticket.toString().padStart(5, '0')).join(', ')}. Me podrías pasar más información sobre el pago.`
    : `Hola, me llamo ${userName} (${userPhone}) y me gustaría saber más sobre los boletos disponibles.`;

  // Encode message for WhatsApp URL
  const whatsappLink = `https://wa.me/523335027497?text=${encodeURIComponent(message)}`;

  // Open WhatsApp link in a new tab
  window.open(whatsappLink, '_blank');
};

  return (
    <section id="inicio" className={styles['inicio-section']}>
      <div className={styles.intro}>
        <h1>¡Participa en la Gran Rifa de un MINI Cooper S Hot Chilli 2013!</h1>
        <p>Esta es tu oportunidad de ganar un increíble MINI Cooper S Hot Chilli 2013, y con solo un boleto podrías ser el afortunado ganador. 
          No pierdas esta oportunidad única de llevarte este espectacular auto a casa.</p>
        <div className={styles.buttonContainerIntro}>
          <button className={styles['btn-reserva']} onClick={handleReserveTickets}>Reserva tus boletos</button> {/* Botón de reserva */}
        </div>
      </div>

      <div className={styles.descripcion}>
        <div className={styles.descripcionContent}>
          <div className={styles.texto}>
            <h2>¡Gana un MINI Cooper S Hot Chilli 2013 con un solo boleto! Participa y lleva a casa el auto de tus sueños por solo $79 pesos.</h2>
            <br />
            <p>Te presento el MINI Cooper S Hot Chilli 2013, una combinación perfecta de estilo y rendimiento con solo 14,000 km recorridos y factura original. Este modelo icónico destaca con un motor 1.6 turbo de 4 cilindros que entrega una potencia de 181 HP, capaz de alcanzar una velocidad máxima de 230 km/h.
            </p><br />
            <p>El interior es una experiencia en sí mismo: asientos de piel que brindan confort, sonido premium Harman Kardon para una calidad de audio excepcional y una pantalla central que mantiene la tecnología al alcance. El techo panorámico añade un toque de elegancia y amplitud al interior. Además, el auto está completamente al día en sus pagos, por lo que no tendrás que preocuparte por nada.
            Con el MINI Cooper S Hot Chilli 2013, obtendrás estilo, potencia y todas las prestaciones de un coche exclusivo y bien cuidado.
            </p>
          </div>
          <div className={styles.grafico}>
            <VideoComponent />
          </div>
        </div>
      </div>

      <div className={styles.fecha}>
        <Countdown />
        <p>El sorteo se llevará a cabo el martes 28 de enero del 2025 a las 20:00 horas en una transmisión en vivo, asegurando total transparencia y emoción hasta el último segundo.</p>
      </div>

      <div className={styles.premio}>
        <h2>El Premio</h2>
        
        <div className={styles.premioContent}>
          <div className={styles.column}>
            <p>El MINI Cooper S Hot Chilli 2013 es un vehículo que combina lujo, rendimiento y tecnología de punta.</p>
            <p><strong>Modelo:</strong> S Hot Chilli 2013</p>
            <p><strong>Año:</strong> 2013</p>
            <p><strong>Kilometraje:</strong> 14,000 km</p>
          </div>
          <div className={styles.column}>
            <p><strong>Especificaciones:</strong></p>
            <ul className={styles.caracteristicas}>
              <li><strong>Motor:</strong> 1.6 turbo de 3 cilindros con una potencia de 181 hp</li>
              <li><strong>Velocidad máxima:</strong> 230 km/h</li>
              <li><strong>Interior:</strong> Asientos de piel, techo panorámico, pantalla central</li>
              <li><strong>Audio:</strong> Sistema de sonido premium Harman Kardon</li>
              <li><strong>Factura:</strong> Original</li>
            </ul>
          </div>
        </div>   
        <PremioCarousel />    
      </div>

      <div className={styles.proceso}>
        <h2>Otros Premios</h2>
        <div className={styles.premiosGrid}>
          <div>
            <strong>2do lugar:</strong> iPhone
          </div>
          <div>
            <strong>3er, 4to y 5to lugar:</strong> $10,000 pesos
          </div>
        </div>
      </div>


      <div className={styles.bonos}>
        <h2 className={styles['bonos-title']}>Bonos</h2>
        <div className={styles['bonos-grid']}>
          <div className={styles.bono}>
            <p><strong>Bono de liquidación rápida:</strong> Playstation 5 ó Xbox Series X</p>
            <p><em>(Válido liquidando en tiempo y forma dentro de las primeras 24 horas del inicio de la rifa)</em></p>
          </div>
          <div className={styles.bono}>
            <p><strong>Bono extra:</strong></p>
            <p>Si compraste 10 boletos, te llevas de bono: $20,000 pesos.</p>
          </div>
          <div className={styles.bono}>
            <p><strong>Bono adicional:</strong> $50,000 pesos</p>
            <p>Al afortunado por interactuar en nuestras publicaciones (dejar una reacción, compartir en modo público o comentar) mínimo en un 80% de todas las publicaciones.</p>
            <p><em>(Válidos pagando en menos de 8 horas después de tu reservación)</em></p>
          </div>
        </div>
      </div>

      <div className={styles.proceso}>
        <h2>Proceso</h2>
        <p>La rifa se llevará a cabo utilizando el sistema de la Lotería Nacional. Se pondrán a la venta un total de 20,000 boletos, y en el caso de que la Lotería Nacional emita 60,000 números, tu boleto tendrá un valor equivalente a 3. Por ejemplo, el Ticket 1 corresponderá a los números 1, 20.001, 40.001; el Ticket 2 a los números 2, 20.002, 40.002; el Ticket 3 a los números 3, 20.003, 40.003, y así sucesivamente.</p>
        <p><strong>¿Cómo funciona la compra de boletos?</strong>
        <br></br>
         Primero, selecciona los boletos que deseas adquirir y resérvalos. Es importante que tengas una cuenta de usuario para poder realizar la compra. Una vez que hayas reservado tus boletos, deberás realizar una transferencia a nuestras cuentas. Luego, envía una captura de pantalla de la transferencia a nuestro <a href="https://wa.me/523335027497?text=Reserve%20el%20folio:%20.%20Les%20mando%20captura%20del%20pago." target="_blank" rel="noopener noreferrer">WhatsApp</a>. Confirmaremos tu pago y te mantendremos informado sobre el estado de tu compra.</p>
      </div>

      <div className={styles.precio}>
        {/* Caja para el precio normal */}
        <div className={styles.precioBox}>
          <div className={styles['discount-flag']}>-18%</div>
          <h3>10x$650</h3>
          <p>Compra 10 boletos por $650 pesos.</p>
        </div>

        {/* Caja para ofertas especiales */}
        <div className={styles.precioBox}>
          <div className={styles['discount-flag']}>-24%</div>
          <h3>20x$1,200</h3>
          <p>Compra 20 boletos por $1,200 pesos.</p>
        </div>

        {/* Caja para ofertas especiales */}
        <div className={styles.precioBox}>
          <div className={styles['discount-flag']}>-37%</div>
          <h3>50x$2,500</h3>
          <p>Compra 50 boletos por $2,500 pesos.</p>
        </div>

        {/* Caja para ofertas especiales */}
        <div className={styles.precioBox}>
          <div className={styles['discount-flag']}>-43%</div>
          <h3>100x$4,500</h3>
          <p>Compra 100 boletos por $4,500 pesos.</p>
        </div>
      </div>

      <div className={styles.proceso}>
        <h2>Boletos disponibles</h2>
        <p>Hay un total de {numTickets.toLocaleString()} boletos disponibles, y cada uno tiene el mismo valor de oportunidad para ganar. El precio del boleto es de $79 pesos.
          ¡Compra el tuyo antes de que se agoten!</p>
  
        {/* Display selected tickets */}
        <div className={styles.selectedTicketsContainer}>
          {selectedTickets.length > 0 ? (
            <div className={styles.selectedTickets}>
              <h3>Boletos Seleccionados: <span>{selectedTickets.length}</span></h3>
              <ul>
                {selectedTickets.map((ticket) => (
                  <li key={ticket}>
                    <strong>{ticket.toString().padStart(5, '0')}</strong> ({(ticket + 20000).toLocaleString()} y {(ticket + 40000).toLocaleString()})
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className={styles.emptyTicketsContainer}></div>
          )} {/* Don't display container when no tickets selected */}
        </div>
  
        <div className={stylesLucky.searchAndLuckyContainer}> 
          <div className={stylesLucky.luckySection}>
            <button
              onClick={() => setLuckyModalVisible(true)} // Abre el modal de suerte
            >
              ¡Me siento con suerte!
            </button>
          </div>
  
          <div className={styles.searchContainer}>
            <form onSubmit={handleSearch} style={{ display: 'flex', width: '100%' }}>
              <input
                type="text"
                className={styles.input}
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                placeholder='Buscar boleto'
              />
              <button type="submit" className={styles.searchButton}>
                <FaSearch />
              </button>
            </form>
          </div>
        </div>
  
        {/* Lucky Modal */}
        <Modal isOpen={luckyModalVisible} onClose={handleCloseLuckyModal}>
          {showLuckyGif ? (
            // Show GIF when the lucky button is clicked
            <div className={stylesLucky.luckyGifContainer}>
              <img src={luckyGif} alt="Suerte" className={stylesLucky.luckyGif} />
            </div>
          ) : (
            // Show input and button when not showing the GIF
            <>
              <div className={stylesLucky.modalRow}>
                <p>¿Cuántos boletos deseas agregar?</p>
                <input
                  type="number"
                  value={luckyTicketCount}
                  onChange={handleInputChange} // Limit the input to 100
                  min="1"
                  max="100"
                />        
              </div>
              <div>
                {/* Show error message if it exists */}
                {errorMessage && (
                  <p style={{ color: 'red', marginTop: '5px' }}>{errorMessage}</p>
                )}
              </div>
              <div className={styles.luckySlotContainer}>
                <button
                  className={`${styles.luckySlotButton} ${isButtonClicked ? styles.clicked : ''}`}
                  onClick={handleLuckyTickets}
                  disabled={isButtonClicked} /* Avoid multiple clicks */
                >
                  {isButtonClicked ? 'Processing...' : 'Agregar boleto de la suerte'}
                </button>
              </div>
            </>
          )}
        </Modal>
        
        {/* Display the lucky added tickets */}
        {luckyTickets.length > 0 && (
          <div className={stylesLucky.luckyMessage}> 
            <h3>¡Boletos de la suerte agregados!</h3>
            <p>Los siguientes boletos han sido agregados: {luckyTickets.map(ticket => ticket.toString().padStart(5, '0')).join(', ')}</p>
          </div>
        )}
  
        <div className={styles.boletos}>
          <Tickets searchValue={inputValue}/>
        </div>
  
        {/* WhatsApp Button */}
        <div className={styles.buttons}>
          <button
            onClick={() => setWhatsappModalVisible(true)}
          >
            Reservar como invitado
          </button>
        </div>
  
        {/* WhatsApp Modal */}
        {whatsappModalVisible && (
          <Modal isOpen={whatsappModalVisible} onClose={() => setWhatsappModalVisible(false)}>
            <div>
              <h3>Ingresa tu información:</h3>
              <form onSubmit={handleSubmit}>
                <div>
                  <label>Nombre:</label>
                  <input
                    type="text"
                    id="name"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    placeholder="Tu nombre"
                    required
                  />
                </div>
                <div>
                  <label>Teléfono:</label>
                  <input
                    type="tel"
                    id="phone"
                    value={userPhone}
                    onChange={(e) => setUserPhone(e.target.value)}
                    placeholder="Tu teléfono"
                    required
                  />
                </div>
                <div className={styles.buttons}>
                  <button
                    type='submit'
                    onClick={handleWhatsAppClick}
                  >
                    Enviar
                  </button>
                </div>
              </form>
            </div>
          </Modal>
        )}
  
        <div className={styles.buttons2}>
          <button onClick={handleGoToCart}>Reservar Boletos</button> {/* Botón para ir al carrito */}
        </div>
      </div>
    </section>
  );
}

export default Inicio;
