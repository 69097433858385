import React, { useContext } from 'react';
import styles from '../Inicio/inicio.module.css';
import { ShopContext } from '../../context/shop-context.js';
import useFetch from '../../hooks/useFetch.js';

function Tickets({ searchValue }) {
  // Fetch available tickets from the database
  const { data, loading, error } = useFetch("https://luckygarage-api.onrender.com/api/tickets/disponibles");
  
  // Access the context for cart items and toggleCartItem function
  const { cartItems, toggleCartItem } = useContext(ShopContext);

  console.log("Tickets component rendered");

  // Handle ticket click
  const handleTicketClick = (ticketId) => {
    console.log("Ticket clicked: ", ticketId);
    toggleCartItem(ticketId);
  };

  // Loading state
  if (loading) {
    return <div>Loading tickets...</div>;
  }

  // Error state
  if (error) {
    return <div>Error fetching tickets: {error.message}</div>;
  }

  // Return if there are no tickets available
  if (!data || !data.data || data.data.length === 0) {
    return <div>No tickets available</div>;
  }

  // Filtrar los boletos según el valor de inputValue
  const filteredTickets = searchValue
    ? data.data.filter((ticket) => ticket.numeroTicket.toString().includes(searchValue))
    : data.data;

  // Sort tickets by numeroTicket in ascending order
  const sortedTickets = filteredTickets.sort((a, b) => a.numeroTicket - b.numeroTicket);

  return (
    <div className={styles.boletosDisponibles}>
      {sortedTickets.map((ticket) => (
        <div
          key={ticket.numeroTicket}
          className={`${styles.boleto} ${cartItems[ticket.numeroTicket] > 0 ? styles.selected : ''}`}
          onClick={() => handleTicketClick(ticket.numeroTicket)}
        >
          {ticket.numeroTicketFormateado || ticket.numeroTicket} {/* Display numeroTicket */}
        </div>
      ))}
    </div>
  );
}

export default Tickets;
