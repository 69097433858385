import React from 'react';
import styles from './footer.module.css'

function Footer() {
  return (
    <footer className={styles.footer}>
      <p>&copy; 2024 Lucky Garage. Todos los derechos reservados.</p>
    </footer>
  );
}

export default Footer;
