import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import styles from './admin.module.css';
import { AuthContext } from '../../context/auth-context.js';
import { FaSearch } from 'react-icons/fa';
import Modal from '../CarroCompras/modal.js'; // Importa el Modal

function AdminProfile() {
  const { user } = useContext(AuthContext);
  const [folios, setFolios] = useState([]);
  const [foliosPagados, setFoliosPagados] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [loadingPagados, setLoadingPagados] = useState(true);
  const [errorPagados, setErrorPagados] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [filteredFolios, setFilteredFolios] = useState([]);
  const [filteredFoliosPagados, setFilteredFoliosPagados] = useState([]);

  // Estado para el modal de errores
  const [errorModalMessage, setErrorModalMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    // Obtener los folios reservados
    const fetchFolios = async () => {
      try {
        const response = await axios.get('https://luckygarage-api.onrender.com/api/admin/folios');
        setFolios(response.data);
        setFilteredFolios(response.data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
        setErrorModalMessage('Error al cargar los folios reservados.');
        setShowErrorModal(true);
      }
    };

    // Obtener los folios pagados
    const fetchFoliosPagados = async () => {
      try {
        const response = await axios.get('https://luckygarage-api.onrender.com/api/admin/foliosPagados');
        setFoliosPagados(response.data);
        setFilteredFoliosPagados(response.data);
        setLoadingPagados(false);
      } catch (err) {
        setErrorPagados(err.message);
        setLoadingPagados(false);
        setErrorModalMessage('Error al cargar los folios pagados.');
        setShowErrorModal(true);
      }
    };

    fetchFolios();
    fetchFoliosPagados();
  }, []);

  const handleMarkAsPaid = async (folioReserva) => {
    try {
      await axios.post(`https://luckygarage-api.onrender.com/api/admin/${folioReserva}/markPaid`);
      setFolios(folios.filter(folio => folio.folioReserva !== folioReserva));
      window.location.reload();
    } catch (err) {
      setErrorModalMessage('Error al marcar como pagado.');
      setShowErrorModal(true);
    }
  };

  const handleMarkAsNotPaid = async (folioReserva) => {
    try {
      await axios.post(`https://luckygarage-api.onrender.com/api/admin/${folioReserva}/markNotPaid`);
      setFolios(folios.filter(folio => folio.folioReserva !== folioReserva));
      window.location.reload();
    } catch (err) {
      setErrorModalMessage('Error al marcar como no pagado.');
      setShowErrorModal(true);
    }
  };

  // Manejar el cambio en el valor de búsqueda
  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchValue(value);
  
    // Helper function to check if the search value matches any ticket or its additional opportunities
    const matchesTicketOrOpportunity = (ticket) => {
      const ticketNumber = ticket.numeroTicket.toString();
      const opportunityOne = (ticket.numeroTicket + 20000).toString();
      const opportunityTwo = (ticket.numeroTicket + 40000).toString();
      return ticketNumber.includes(value) || opportunityOne.includes(value) || opportunityTwo.includes(value);
    };
  
    // Filtering folios where the search term matches either the ticket number, email, or additional opportunities
    const filtered = folios.filter(folio =>
      folio.tickets.some(ticket => matchesTicketOrOpportunity(ticket)) ||
      folio.usuario.email.toLowerCase().includes(value)
    );
    setFilteredFolios(filtered);
  
    // Filtering pagados in a similar way
    const filteredPagados = foliosPagados.filter(folio =>
      folio.tickets.some(ticket => matchesTicketOrOpportunity(ticket)) ||
      folio.usuario.email.toLowerCase().includes(value)
    );
    setFilteredFoliosPagados(filteredPagados);
  };

  if (loading || loadingPagados) return <p>Cargando perfil...</p>;
  if (error || errorPagados) return <p>Error: {error || errorPagados}</p>;

  return (
    <div className={styles.container}>
      <div className={styles['profile-header']}>
        <h2>Perfil</h2>
      </div>
      <div className={styles['profile-details']}>
        <p><strong>Nombre:</strong> {user?.nombre}</p>
        <p><strong>Email:</strong> {user?.email}</p>
      </div>

      {/* Search Bar */}
      <div className={styles.searchContainer}>
        <form onSubmit={(e) => e.preventDefault()} style={{ display: 'flex', width: '100%' }}>
          <input
            type="text"
            placeholder="Buscar boleto o correo"
            value={searchValue}
            onChange={handleSearchChange}
            className={styles.input}
          />
          <button type="submit" className={styles.searchButton}>
            <FaSearch />
          </button>
        </form>
      </div>

      {/* Sección de Folios Reservados */}
      <div className={styles['folios-section']}>
        <div className={styles['folio-header']}>
          <h2>Folios reservados</h2>
        </div>
        {filteredFolios.length > 0 ? (
          filteredFolios.map((folio) => (
            <div className={styles['folio-card']} key={folio.folioReserva}>
              <h3>Folio: {folio.folioReserva}</h3>
              <p><strong>Información del Usuario</strong></p>
              <p><strong>Nombre:</strong> {folio.usuario.nombre}</p>
              <p><strong>Email:</strong> {folio.usuario.email}</p>
              <p><strong>Teléfono:</strong> {folio.usuario.telefono}</p>

              <h3>Tickets:</h3>
              <div className={styles.ticketsContainer}>
                {folio.tickets.map((ticket) => (
                  <div key={ticket.numeroTicket} className={styles.boleto}>
                    {ticket.numeroTicket.toString().padStart(5, '0')}
                  </div>
                ))}
              </div>

              <p><strong>Oportunidades adicionales:</strong></p>
              {folio.tickets.length > 0 && (
                <div key={folio.folioReserva} className={styles.opportunitiesContainer}>
                  <p>
                    {/* Muestra los tickets con sus oportunidades formateadas */}
                    {folio.tickets
                      .map(ticket => 
                        `${(ticket.numeroTicket + 20000).toLocaleString()} y ${(ticket.numeroTicket + 40000).toLocaleString()}`
                      )
                      .join('; ')} {/* Los pares de números están separados por "; " */}
                  </p>
                </div>
              )}

              <div className={styles['button-container']}>
                <button onClick={() => handleMarkAsPaid(folio.folioReserva)}>Pagado</button>
                <button className={styles.notPaidButton} onClick={() => handleMarkAsNotPaid(folio.folioReserva)} >
                  No Pagado
                </button>
              </div>
            </div>
          ))
        ) : (
          <p className={styles.noFoliosMessage}>No hay folios reservados.</p>
        )}
      </div>

      {/* Sección de Folios Pagados */}
      <div className={styles['folios-section']}>
        <div className={styles['folio-header']}>
          <h2>Folios Pagados</h2>
        </div>
        {filteredFoliosPagados.length > 0 ? (
          filteredFoliosPagados.map((folio) => (
            <div className={styles['folio-card']} key={folio.folioReserva}>
              <h3>Folio: {folio.folioReserva}</h3>
              <p><strong>Información del Usuario</strong></p>
              <p><strong>Nombre:</strong> {folio.usuario.nombre}</p>
              <p><strong>Email:</strong> {folio.usuario.email}</p>
              <p><strong>Teléfono:</strong> {folio.usuario.telefono}</p>

              <h3>Tickets:</h3>
              <div className={styles.ticketsContainer}>
                {folio.tickets.map((ticket) => (
                  <div key={ticket.numeroTicket} className={styles.boleto}>
                    {ticket.numeroTicket.toString().padStart(5, '0')}
                  </div>
                ))}
              </div>

              <p><strong>Oportunidades adicionales:</strong></p>
              {folio.tickets.length > 0 && (
                <div key={folio.folioReserva} className={styles.opportunitiesContainer}>
                  <p>
                    {/* Muestra los tickets con sus oportunidades formateadas */}
                    {folio.tickets
                      .map(ticket => 
                        `${(ticket.numeroTicket + 20000).toLocaleString()} y ${(ticket.numeroTicket + 40000).toLocaleString()}`
                      )
                      .join('; ')} {/* Los pares de números están separados por "; " */}
                  </p>
                </div>
              )}
            </div>
          ))
        ) : (
          <p className={styles.noFoliosMessage}>No hay folios pagados.</p>
        )}
      </div>

      {/* Mostrar Modal de error si hay */}
      <Modal isOpen={showErrorModal} onClose={() => setShowErrorModal(false)}
      >
        <p>{errorModalMessage}</p>
      </Modal>
    </div>
  );
}

export default AdminProfile;
