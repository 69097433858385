import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './auth.module.css';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import Modal from '../CarroCompras/modal.js'; 

const Register = () => {
  const [formData, setFormData] = useState({
    nombre: '',
    telefono: '',
    email: '',
    password: '',
    confirmPassword: ''
  });

  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // State for success message
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar la visibilidad del modal

  // Function to validate email format
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password, confirmPassword, email } = formData;

    if (!validateEmail(email)) {
      setError('El correo electrónico no tiene un formato válido');
      setIsModalOpen(true); // Mostrar el modal en caso de error
      return;
    }

    if (password.length < 8) {
      setError('La contraseña debe tener al menos 8 caracteres');
      setIsModalOpen(true); // Mostrar el modal en caso de error
      return;
    }

    if (password !== confirmPassword) {
      setError('Las contraseñas no coinciden');
      setIsModalOpen(true); // Mostrar el modal en caso de error
      return;
    }

    try {
      const response = await fetch('https://luckygarage-api.onrender.com/api/auth/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      const data = await response.json();
      if (!response.ok) {
        setError(data.message || 'Error al registrar');
        setIsModalOpen(true); // Mostrar el modal si hay un error en la respuesta del servidor
      } else {
        // Set the success message and clear the form data
        setSuccessMessage('El correo ha sido enviado. Verifica spam en caso de ser necesario.');
        setFormData({
          nombre: '',
          telefono: '',
          email: '',
          password: '',
          confirmPassword: ''
        });
        setIsModalOpen(true); // Mostrar el modal cuando se haya registrado con éxito
      }
    } catch (err) {
      setError('Error en el servidor');
      setIsModalOpen(true); // Mostrar el modal en caso de error del servidor
    }
  };

  // Función para cerrar el modal
  const handleCloseModal = () => {
    setIsModalOpen(false); // Cerrar el modal
    setError(''); // Limpiar el mensaje de error al cerrar
  };

  return (
    <div className={styles.body}>
      <div className={styles.container}>
        <h2>{successMessage ? 'Verifica tu correo' : 'Registrarse'}</h2>

        {/* Mostrar mensaje de éxito si el correo fue enviado */}
        {successMessage ? (
          <p style={{ color: 'green' }}>{successMessage}</p>
        ) : (
          <>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="nombre"
                id="nombre"
                placeholder="Nombre de Usuario"
                value={formData.nombre}
                onChange={handleChange}
                required
              />
              <input
                type="tel"
                name="telefono"
                id="telefono"
                placeholder="Teléfono (opcional)"
                value={formData.telefono}
                onChange={handleChange}
                pattern="[0-9]{10}"
                title="Número de teléfono válido debe tener 10 dígitos"
              />
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Correo Electrónico"
                value={formData.email}
                onChange={handleChange}
                required
              />
              <div className={styles.passwordContainer}>
                <input
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  id="password"
                  placeholder="Contraseña (mínimo 8 caracteres)"
                  value={formData.password}
                  onChange={handleChange}
                  minLength={8} // HTML validation
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className={styles.eyeButton}
                >
                  {showPassword ? <EyeSlash /> : <Eye />}
                </button>
              </div>
              <div className={styles.passwordContainer}>
                <input
                  type={showConfirmPassword ? 'text' : 'password'}
                  name="confirmPassword"
                  id="confirmPassword"
                  placeholder="Confirmar Contraseña"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  minLength={8} // HTML validation
                  required
                />
                <button
                  type="button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  className={styles.eyeButton}
                >
                  {showConfirmPassword ? <EyeSlash /> : <Eye />}
                </button>
              </div>
              <button type="submit">Crear Cuenta</button>
            </form>
            <div className={styles['auth-link']}>
              ¿Ya tienes una cuenta? <Link to="/login">Inicia sesión</Link>
            </div>
          </>
        )}
      </div>

      {/* Modal para mostrar mensajes */}
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        <p>{error ? error : successMessage}</p>
      </Modal>
    </div>
  );
};

export default Register;