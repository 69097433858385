import React from 'react';
import sampleVideo from '../../assets/instories_142F3C2A-285A-4C82-937E-A32EFB852D43.mp4'; // Importa tu archivo de video

export default function VideoComponent() {
  return (
    <div
      style={{
        width: '100%',
        maxWidth: '300px', // Tamaño máximo del video
        margin: '0 auto', // Centrado horizontal
        borderRadius: '10px',
        overflow: 'hidden',
        backgroundColor: '#FFFFFF',
      }}
    >
      <video controls autoPlay muted loop style={{ width: '100%', height: 'auto'}}>
        <source src={sampleVideo} type="video/mp4" />
        Tu navegador no soporta el elemento de video.
      </video>
    </div>
  );
}
