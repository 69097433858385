import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './context/auth-context.js';

const ProtectedRoute = ({ adminComponent: AdminComponent, userComponent: UserComponent }) => {
  const { user, loading } = useContext(AuthContext);

  // Mostrar una pantalla de carga mientras se verifica el estado del usuario
  if (loading) {
    return <p>Loading...</p>;
  }

  // Si no hay usuario autenticado, redirigir al login
  if (!user) {
    return <Navigate to="/Login" replace />;
  }

  // Si es admin, mostrar el componente de admin, si no, el componente de usuario
  return user.tipo === 'admin' ? <AdminComponent /> : <UserComponent />;
};

export default ProtectedRoute;