import React, { useContext, useState } from 'react';
import styles from './carroCompras.module.css';
import { ShopContext } from '../../context/shop-context.js';
import { TicketContext } from '../../context/ticket-context.js';
import emoticonImage from '../../assets/boletoCarroCompras.png'; 
import Modal from './modal.js';
import CloseButton from 'react-bootstrap/esm/CloseButton.js'; 
import { useNavigate } from 'react-router-dom'; // Importar useNavigate para redirigir

const TICKET_PRICE = 79;
const DISCOUNT_10_TICKETS = 650;
const DISCOUNT_20_TICKETS = 1200;
const DISCOUNT_50_TICKETS = 2500;
const DISCOUNT_100_TICKETS = 4500;

function CarroCompras() {
    const { cartItems, reserveTickets, message, messageVisible, removeFromCart, setMessageVisible, setMessage } = useContext(ShopContext);
    const navigate = useNavigate(); // Hook para redirigir

    // Access the available tickets from TicketContext
    const { data: availableTickets = [], loading, error } = useContext(TicketContext); 

    // Calcular la cantidad total de boletos
    const totalQuantity = Object.values(cartItems).reduce((total, quantity) => total + quantity, 0);

    // New state for WhatsApp modal and user name
    const [whatsappModalVisible, setWhatsappModalVisible] = useState(false);
    const [userName, setUserName] = useState('');
    const [userPhone, setUserPhone] = useState(''); 

    // Función para calcular el costo total basado en la cantidad de boletos
    // Helper function to calculate total cost based on the given pricing logic
    const calculateTotalCost = (quantity) => {    
        let totalCost = 0;
    
        // Handle blocks of 100 tickets
        const blocksOf100 = Math.floor(quantity / 100);
        const remainingAfter100 = quantity % 100;
    
        // Handle blocks of 50 tickets
        const blocksOf50 = Math.floor(remainingAfter100 / 50);
        const remainingAfter50 = remainingAfter100 % 50;
    
        // Handle blocks of 20 tickets
        const blocksOf20 = Math.floor(remainingAfter50 / 20);
        const remainingAfter20 = remainingAfter50 % 20;
    
        // Handle blocks of 10 tickets
        const blocksOf10 = Math.floor(remainingAfter20 / 10);
        const remainingAfter10 = remainingAfter20 % 10;
    
        // Handle remaining tickets
        const singleTickets = remainingAfter10;
    
        totalCost += blocksOf100 * DISCOUNT_100_TICKETS;
        totalCost += blocksOf50 * DISCOUNT_50_TICKETS;
        totalCost += blocksOf20 * DISCOUNT_20_TICKETS;
        totalCost += blocksOf10 * DISCOUNT_10_TICKETS;
        totalCost += singleTickets * TICKET_PRICE;
    
        return totalCost;
    };

    // Costo total de los boletos
    const totalCost = calculateTotalCost(totalQuantity);

    // Función para manejar la reserva de boletos
    const handleReserveAllTickets = async () => {
        const token = localStorage.getItem('authToken');
    
        // Verificar si el usuario está autenticado
        if (!token) {
            setMessage("Debes iniciar sesión para reservar los boletos.");
            setMessageVisible(true); // Abrir el modal si hay un error
            return;
        }
    
        // Verificar si hay boletos en el carrito
        if (totalQuantity === 0) {
            setMessage("Tu carrito está vacío.");
            setMessageVisible(true); // Abrir el modal si hay un error
            return;
        }
    
        try {
            // Intentar reservar los boletos
            await reserveTickets(); // Llama a la función sin el argumento si no lo necesitas
            console.log("Boletos reservados con éxito");
        } catch (err) {
            // Si ocurre un error, mostrar el mensaje en el modal
            setMessage("Hubo un error al reservar los boletos. Inténtalo de nuevo.");
            setMessageVisible(true);
        }
    };

    // Función para redirigir al inicio de sesión
    const handleLoginRedirect = () => {
        navigate('/Login'); // Redirige al usuario a la página de login
    };

    // Función para redirigir al registro
    const handleRegisterRedirect = () => {
        navigate('/Registro'); // Redirige al usuario a la página de registro
    };

    // Función para cerrar el modal
    const handleCloseModal = () => {
        setMessageVisible(false); // Cerrar el modal
    };

    // Get the list of selected tickets from cartItems
      const selectedTickets = Object.keys(cartItems)
        .filter((ticketId) => cartItems[ticketId] > 0)
        .map(Number); //Number // Convert keys to numbers

    const handleSubmit = async (e) => {
        e.preventDefault();
      
        if (!userName.trim() || !userPhone.trim()) {
          alert('Por favor ingresa tu nombre y teléfono.');
          return;
        }

        const ticketKeys = Object.keys(cartItems).filter((key) => cartItems[key] > 0);

        // Verificar si hay boletos válidos para enviar
        if (ticketKeys.length === 0) {
            alert('No has seleccionado ningún boleto.');
            return;
        }

        const data = {
            name: userName,
            phone: userPhone,
            tickets: ticketKeys.map(Number), // Convertir a números si es necesario
        };
      
        try {
          // Enviar los datos al backend
          const response = await fetch('https://luckygarage-api.onrender.com/api/invitado/interest', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
          });
      
          if (!response.ok) throw new Error('Error al guardar los datos');
      
          // Redirigir a WhatsApp con el mensaje
          const message = data.tickets.length  > 0
            ? `Hola, me llamo ${userName} (${userPhone}) y me gustaría reservar los siguientes boletos: ${selectedTickets.map(ticket => ticket.toString().padStart(5, '0')).join(', ')}. Me podrias pasar más información sobre el pago.`
            : `Hola, me llamo ${userName} (${userPhone}) y me gustaría saber más sobre los boletos disponibles.`;
      
          const whatsappLink = `https://wa.me/523335027497?text=${encodeURIComponent(message)}`;
          window.open(whatsappLink, '_blank');
      
          // Cerrar modal
          setWhatsappModalVisible(false);
      
        } catch (error) {
          console.error('Error al enviar datos:', error);
          alert('Ocurrió un error al guardar los datos.');
        }
      };

       const handleWhatsAppClick = () => {
      // Ensure user inputs are valid
      if (!userName.trim() || !userPhone.trim()) {
        alert('Por favor ingresa tu nombre y teléfono.');
        return;
      }
    
      // Generate WhatsApp message based on selected tickets
      const message = selectedTickets.length > 0
        ? `Hola, me llamo ${userName} (${userPhone}) y me gustaría reservar los siguientes boletos: ${selectedTickets.map(ticket => ticket.toString().padStart(5, '0')).join(', ')}. Me podrías pasar más información sobre el pago.`
        : `Hola, me llamo ${userName} (${userPhone}) y me gustaría saber más sobre los boletos disponibles.`;
    
      // Encode message for WhatsApp URL
      const whatsappLink = `https://wa.me/523335027497?text=${encodeURIComponent(message)}`;
    
      // Open WhatsApp link in a new tab
      window.open(whatsappLink, '_blank');
    };

    return (
        <div className={styles.body}>
            <div className={styles.container}>
                <h2>Tu carro de compras</h2>
                <div className={styles.item}>
                    {Object.keys(cartItems).map((key) => {
                        const quantity = cartItems[key];
                        if (quantity !== 0) {
                            return (
                                <div key={key} className={styles.cartItem}>
                                    <img src={emoticonImage} alt="Emoticon" className={styles.emoticon} />
                                    <div className={styles.cartDetails}>
                                        <p>Número: {key}</p>
                                        <p>Cantidad: {quantity}</p>
                                        <p>Oportunidades adicionales: {(Number(key) + 20000).toLocaleString()} y {(Number(key) + 40000).toLocaleString()}</p>
                                    </div>
                                    {/* Agregar botón para remover boleto */}
                                    <CloseButton onClick={() => removeFromCart(key)} className={styles.closeButton} />
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>

                <div className={styles.total}>
                    <p>Total boletos: {totalQuantity}</p>
                    <p className={styles.totalCost}>Total a pagar: $ {totalCost} MXN</p>
                </div>

                {/* WhatsApp Button */}
                  <div className={styles.buttons}>
                    <button
                      onClick={() => setWhatsappModalVisible(true)}
                    >
                      Reservar como invitado
                    </button>
                  </div>

                {/* WhatsApp Modal */}
                  {whatsappModalVisible && (
                    <Modal isOpen={whatsappModalVisible} onClose={() => setWhatsappModalVisible(false)}>
                      <div>
                        <h3>Ingresa tu información:</h3>
                        <form onSubmit={handleSubmit}>
                          <div>
                            <label>Nombre:</label>
                            <input
                              type="text"
                              id="name"
                              value={userName}
                              onChange={(e) => setUserName(e.target.value)}
                              placeholder="Tu nombre"
                              required
                            />
                          </div>
                          <div>
                            <label>Teléfono:</label>
                            <input
                              type="tel"
                              id="phone"
                              value={userPhone}
                              onChange={(e) => setUserPhone(e.target.value)}
                              placeholder="Tu teléfono"
                              required
                            />
                          </div>
                          <div className={styles.buttons}>
                              <button
                               type='submit'
                               onClick={handleWhatsAppClick}
                              >
                                Enviar
                              </button>
                          </div>
                        </form>
                      </div>
                    </Modal>
                  )}

                <div className={styles.buttons2}>
                    <button onClick={handleReserveAllTickets}>Reservar Boletos</button>
                </div>
            </div>
            
            {/* Modal para mostrar errores */}
            <Modal isOpen={messageVisible} onClose={handleCloseModal}>
                <p>{message ? message : "Hubo un error al reservar los boletos. Por favor intenta de nuevo."}</p>
                {message === "Debes iniciar sesión para reservar los boletos." && (
                    <div className={styles.modalButtons}>
                        <button onClick={handleLoginRedirect} className={styles.modalButton}>Iniciar sesión</button>
                        <button onClick={handleRegisterRedirect} className={styles.modalButton}>Registrarse</button>
                    </div>
                )}
            </Modal>
        </div>
    );
}

export default CarroCompras;
