import React from 'react';
import styles from './modal.module.css';

const Modal = ({ isOpen, onClose, children }) => {
  return (
    <div className={`${styles.modalOverlay} ${isOpen ? styles.active : ''}`}>
      <div className={styles.modalContent}>
        <button onClick={onClose} className={styles.closeButton}>
          X
        </button>
        <div className={styles.modalBody}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;