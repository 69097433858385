import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/auth-context.js';
import Modal from '../CarroCompras/modal.js'; 
import styles from './auth.module.css';
import axios from 'axios';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const { login, error, loading } = useContext(AuthContext);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [resendLoading, setResendLoading] = useState(false);
  const [showModal, setShowModal] = useState(false); // Estado para controlar el modal
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setVerificationMessage('');
    try {
      await login(formData.email, formData.password);

      if (!error) {
        navigate('/');
        window.location.reload();
      }
    } catch (err) {
      if (err.message.includes('Error al iniciar sesión. Tu cuenta no ha sido verificada.')) {
        setVerificationMessage('Tu cuenta no ha sido verificada. Por favor, revisa tu bandeja de entrada o la carpeta de spam.');
        setShowModal(true); // Muestra el modal cuando hay un error de verificación
      } else if (err.message.includes('Credenciales incorrectas')) {
        setVerificationMessage('Las credenciales que ingresaste son incorrectas. Verifica tu correo y contraseña e intenta de nuevo.');
        setShowModal(true);
      } else {
        setVerificationMessage('Hubo un problema al iniciar sesión. Por favor, intenta de nuevo más tarde.');
        setShowModal(true);
        console.log(err);
      }
    }
  };

  const handleResendVerification = async () => {
    setResendLoading(true);
    try {
      await axios.post('https://luckygarage-api.onrender.com/api/usuarios/resend-verification', { email: formData.email });
      setVerificationMessage('Correo de verificación reenviado. Revisa tu bandeja de entrada.');
      setShowModal(true);
    } catch (error) {
      setVerificationMessage('Hubo un problema al reenviar el correo de verificación.');
      setShowModal(true);
    } finally {
      setResendLoading(false);
    }
  };

  const closeModal = () => {
    setShowModal(false); // Cierra el modal
  };

  return (
    <div className={styles.body}>
      <div className={styles.container}>
        <h2>Iniciar Sesión</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            placeholder="Correo Electrónico"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="password"
            name="password"
            placeholder="Contraseña"
            value={formData.password}
            onChange={handleChange}
            required
          />
          <button type="submit" disabled={loading}>
            {loading ? 'Cargando...' : 'Iniciar Sesión'}
          </button>
        </form>
        {verificationMessage.includes('Error al iniciar sesión. Tu cuenta no ha sido verificada.') && (
          <button
            onClick={handleResendVerification}
            disabled={resendLoading}
          >
            {resendLoading ? 'Reenviando...' : 'Reenviar Correo de Verificación'}
          </button>
        )}
        <div className={styles['auth-link']}>
          ¿No tienes una cuenta? <Link to="/Registro">Regístrate</Link>
        </div>
      </div>

      {/* Modal component */}
      <Modal isOpen={showModal} onClose={closeModal} title="Mensaje de Verificación" >
        <p>{verificationMessage} </p>
      </Modal>
    </div>
  );
};

export default Login;
