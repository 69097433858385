import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './comprarboletos.module.css';
import stylesBoletos from '../Inicio/inicio.module.css';
import { ShopContext } from '../../context/shop-context.js';
import { TicketContext } from '../../context/ticket-context.js';
import { FaSearch } from 'react-icons/fa';
import Modal from '../CarroCompras/modal.js'; // Suponiendo que ya tienes un componente modal
import luckyGif from '../../assets/slotmachine.gif'; // Assuming you have a GIF asset

function ComprarBoletos() {
  const navigate = useNavigate();
  
  // State for the search input
  const [searchValue, setSearchValue] = useState('');
  
  // State for the "Me siento con suerte" modal
  const [luckyModalVisible, setLuckyModalVisible] = useState(false);
  const [luckyTicketCount, setLuckyTicketCount] = useState(1); // Quantity of random tickets to add
  const [luckyAddedTickets, setLuckyAddedTickets] = useState([]);
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showLuckyGif, setShowLuckyGif] = useState(false); // State to show/hide the GIF

  // Access the available tickets from TicketContext
  const { data: availableTickets = [], loading, error } = useContext(TicketContext); 
  
  // Access cartItems, addToCart, and removeFromCart from ShopContext
  const { cartItems, addToCart, removeFromCart } = useContext(ShopContext);

  // State to store selected tickets
  //const [selectedTickets, setSelectedTickets] = useState([]);

   // New state for WhatsApp modal and user name
  const [whatsappModalVisible, setWhatsappModalVisible] = useState(false);
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState(''); 

  // Handle ticket click (add or remove from cart)
  const handleTicketClick = (ticketId) => {
    if (cartItems[ticketId] > 0) {
      removeFromCart(ticketId);
      //setSelectedTickets(selectedTickets.filter(ticket => ticket !== ticketId)); // Remove from selected tickets
    } else {
      addToCart(ticketId);
      //setSelectedTickets([...selectedTickets, ticketId]); // Add to selected tickets
    }
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchValue(e.target.value);
  };

  // Handle reserve button click
  const handleReserveClick = () => {
    navigate('/CarroCompras');
  };

  // Function to handle adding random tickets
  const handleLuckyTickets = () => {
    // Disable the button once it's clicked
    setIsButtonClicked(true);

    // Validate if luckyTicketCount exceeds the limit
    if (luckyTicketCount > 100) {
      setErrorMessage("No puedes agregar más de 100 boletos.");
      setIsButtonClicked(false); // Re-enable the button
      return;
    }

    // Filter only available tickets and exclude those already in the cart
    let availableTicketNumbers = availableTickets
      .filter((ticket) => ticket.estado === 'disponible' && !cartItems[ticket.numeroTicket])
      .map((ticket) => ticket.numeroTicket);

    if (availableTicketNumbers.length === 0) {
      setErrorMessage("No hay boletos disponibles para agregar o ya están en tu carrito.");
      setIsButtonClicked(false); // Re-enable the button
      return;
    }

    // Ensure the number of tickets to add doesn't exceed available tickets
    if (luckyTicketCount > availableTicketNumbers.length) {
      setErrorMessage(
        `Solo hay ${availableTicketNumbers.length} boletos disponibles. No puedes agregar más de esa cantidad.`
      );
      setLuckyTicketCount(availableTicketNumbers.length);
      setIsButtonClicked(false); // Re-enable the button
      return;
    }

    // Array to hold the selected tickets
    const ticketsToAdd = [];

    // Select random, unique tickets that are not already in the cart
    for (let i = 0; i < luckyTicketCount; i++) {
      const randomIndex = Math.floor(Math.random() * availableTicketNumbers.length);
      const randomTicketNumber = availableTicketNumbers[randomIndex];
      ticketsToAdd.push(randomTicketNumber);

      // Remove the selected ticket from availableTicketNumbers to avoid duplicates
      availableTicketNumbers = availableTicketNumbers.filter(
        (ticket) => ticket !== randomTicketNumber
      );
    }

    // Add the selected tickets to the cart
    ticketsToAdd.forEach((ticket) => {
      if (cartItems[ticket]) {
        cartItems[ticket] += 1; // Increment quantity if ticket is already in the cart
      } else {
        cartItems[ticket] = 1; // Add new ticket to the cart
      }
    });

    // Update state with the added tickets and show the GIF
    setLuckyAddedTickets(ticketsToAdd);
    setShowLuckyGif(true);

    // After 4 seconds, hide the GIF and close the modal
    setTimeout(() => {
      setShowLuckyGif(false);
      setLuckyModalVisible(false);
      setIsButtonClicked(false); // Re-enable the button
    }, 3000);
  };


  // Function to close the lucky modal
  const handleCloseLuckyModal = () => {
    setLuckyModalVisible(false);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
  
    // If the value is empty, allow it temporarily
    if (value === '') {
      setLuckyTicketCount(''); // Allow empty input
      setErrorMessage(''); // Clear any error messages while typing
      return;
    }
  
    // Parse the value to an integer
    const parsedValue = parseInt(value, 10);
  
    // If the parsed value is NaN (invalid number), exit early
    if (isNaN(parsedValue)) {
      return;
    }
  
    // Validate the input value and update the state accordingly
    if (parsedValue > 100) {
      setErrorMessage('No puedes agregar más de 100 boletos.');
      setLuckyTicketCount(100); // Set the max value to 100
    } else if (parsedValue < 1) {
      setErrorMessage('El número mínimo es 1.');
      setLuckyTicketCount(1); // Set the minimum value to 1
    } else {
      setErrorMessage(''); // Clear error messages if the input is valid
      setLuckyTicketCount(parsedValue); // Update the state with the valid number
    }
  };  

  // Timer to remove the lucky message after 2 minutes
  useEffect(() => {
    if (luckyAddedTickets.length > 0) {
      const timer = setTimeout(() => {
        setLuckyAddedTickets([]);
      }, 120000); // 2 minutes in milliseconds

      return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
    }
  }, [luckyAddedTickets]);

  if (loading) {
    return <div>Loading tickets...</div>;
  }

  if (error) {
    return <div>Error fetching tickets: {error.message}</div>;
  }

  if (availableTickets.length === 0) {
    return <div>No tickets available</div>;
  }

  // Filter tickets based on searchValue
  const filteredTickets = searchValue
    ? availableTickets.filter((ticket) =>
        ticket.numeroTicket.toString().includes(searchValue)
      )
    : availableTickets;

  // Sort filtered tickets by numeroTicket in ascending order
  const sortedTickets = filteredTickets.sort((a, b) => a.numeroTicket - b.numeroTicket);

  // Get the list of selected tickets from cartItems
  const selectedTickets = Object.keys(cartItems)
    .filter((ticketId) => cartItems[ticketId] > 0)
    .map(Number); //Number // Convert keys to numbers

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!userName.trim() || !userPhone.trim()) {
      alert('Por favor ingresa tu nombre y teléfono.');
      return;
    }
  
    const data = {
      name: userName,
      phone: userPhone,
      tickets: selectedTickets,
    };
  
    try {
      // Enviar los datos al backend
      const response = await fetch('https://luckygarage-api.onrender.com/api/invitado/interest', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) throw new Error('Error al guardar los datos');
  
      // Redirigir a WhatsApp con el mensaje
      const message = selectedTickets.length > 0
        ? `Hola, me llamo ${userName} (${userPhone}) y me gustaría reservar los siguientes boletos: ${selectedTickets.map(ticket => ticket.toString().padStart(5, '0')).join(', ')}. Me podrias pasar más información sobre el pago.`
        : `Hola, me llamo ${userName} (${userPhone}) y me gustaría saber más sobre los boletos disponibles.`;
  
      const whatsappLink = `https://wa.me/523335027497?text=${encodeURIComponent(message)}`;
      window.open(whatsappLink, '_blank');
  
      // Cerrar modal
      setWhatsappModalVisible(false);
  
    } catch (error) {
      console.error('Error al enviar datos:', error);
      alert('Ocurrió un error al guardar los datos.');
    }
  };

  const handleWhatsAppClick = () => {
  // Ensure user inputs are valid
  if (!userName.trim() || !userPhone.trim()) {
    alert('Por favor ingresa tu nombre y teléfono.');
    return;
  }

  // Generate WhatsApp message based on selected tickets
  const message = selectedTickets.length > 0
    ? `Hola, me llamo ${userName} (${userPhone}) y me gustaría reservar los siguientes boletos: ${selectedTickets.map(ticket => ticket.toString().padStart(5, '0')).join(', ')}. Me podrías pasar más información sobre el pago.`
    : `Hola, me llamo ${userName} (${userPhone}) y me gustaría saber más sobre los boletos disponibles.`;

  // Encode message for WhatsApp URL
  const whatsappLink = `https://wa.me/523335027497?text=${encodeURIComponent(message)}`;

  // Open WhatsApp link in a new tab
  window.open(whatsappLink, '_blank');
};
  
  return (
    <section id="buy" className={styles['buy-section']}>
      <h2>Boletos Disponibles: ¡Asegura el Tuyo!</h2>
      <p>
        Aquí puedes ver la cantidad de boletos restantes. No esperes hasta el último momento,
        ya que los boletos se están vendiendo rápidamente.
      </p>

      {/* Display selected tickets */}
      <div className={styles.selectedTicketsContainer}>
        {selectedTickets.length > 0 ? (
          <div className={styles.selectedTickets}>
            <h3>Boletos Seleccionados: <span>{selectedTickets.length}</span></h3>
            <ul>
              {selectedTickets.map((ticket) => (
                <li key={ticket.numeroTicketFormateado}>
                  <strong>{ticket.toString().padStart(5, '0')}</strong> ({(ticket + 20000).toLocaleString()} y {(ticket + 40000).toLocaleString()})
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className={styles.emptyTicketsContainer}></div>
        )} {/* Don't display container when no tickets selected */}
      </div>

      <div className={styles.searchAndLuckyContainer}> 
        {/* Button to trigger the "Me siento con suerte" modal */}
        <div className={styles.luckySection}>
          <button onClick={() => setLuckyModalVisible(true)}>¡Me siento con suerte!</button>
        </div>

        {/* Search Bar */}
        <div className={stylesBoletos.searchContainer}>
          <form onSubmit={(e) => e.preventDefault()} style={{ display: 'flex', width: '100%' }}>
            <input
              type="text"
              placeholder="Buscar boleto"
              value={searchValue}
              onChange={handleSearchChange}
              className={stylesBoletos.input}
            />
            <button type="submit" className={stylesBoletos.searchButton}>
              <FaSearch />
            </button>
          </form>
        </div>
      </div>
      
      {/* Lucky Modal */}
      <Modal isOpen={luckyModalVisible} onClose={handleCloseLuckyModal}>
        {showLuckyGif ? (
          // Show GIF when the lucky button is clicked
          <div className={styles.luckyGifContainer}>
            <img src={luckyGif} alt="Suerte" className={styles.luckyGif} />
          </div>
        ) : (
          // Show input and button when not showing the GIF
          <>
            <div className={styles.modalRow}>
              <p>¿Cuántos boletos deseas agregar?</p>
              <input
                type="number"
                value={luckyTicketCount}
                onChange={handleInputChange} // Limit the input to 100
                min="1"
                max="100"
              />        
            </div>
            <div>
              {/* Show error message if it exists */}
              {errorMessage && (
                <p style={{ color: 'red', marginTop: '5px' }}>{errorMessage}</p>
              )}
            </div>
            <div className={styles.buttonsInv}>
              <button
                className={`${styles.buttonsInv} ${isButtonClicked ? 'clicked' : ''}`}
                onClick={handleLuckyTickets}
                disabled={isButtonClicked} /* Avoid multiple clicks */
              >
                {isButtonClicked ? '' : 'Agregar boleto de la suerte'}
              </button>
            </div>
          </>
        )}
      </Modal>

      {/* Display the lucky added tickets */}
      {luckyAddedTickets.length > 0 && (
        <div className={styles.luckyMessage}> 
          <h3>¡Boletos de la suerte agregados!</h3>
          <p>Los siguientes boletos han sido agregados: {luckyAddedTickets.map(ticket => ticket.toString().padStart(5, '0')).join(', ')}</p>
        </div>
      )}

      <div className={stylesBoletos.boletos}>
        <div className={stylesBoletos.boletosDisponibles}>
          {sortedTickets.map((ticket) => (
            <div
              key={ticket.numeroTicket}
              className={`${stylesBoletos.boleto} ${cartItems[ticket.numeroTicket] > 0 ? stylesBoletos.selected : ''}`}
              onClick={() => handleTicketClick(ticket.numeroTicket)}
            >
              {ticket.numeroTicketFormateado || ticket.numeroTicket}
            </div>
          ))}
        </div>
      </div>

      {/* WhatsApp Button */}
      <div className={styles.buttonsInv}>
        <button
          onClick={() => setWhatsappModalVisible(true)}
        >
          Reservar como invitado
        </button>
      </div>

      {/* WhatsApp Modal */}
      {whatsappModalVisible && (
        <Modal isOpen={whatsappModalVisible} onClose={() => setWhatsappModalVisible(false)}>
          <div>
            <h3>Ingresa tu información:</h3>
            <form onSubmit={handleSubmit}>
              <div>
                <label>Nombre:</label>
                <input
                  type="text"
                  id="name"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  placeholder="Tu nombre"
                  required
                />
              </div>
              <div>
                <label>Teléfono:</label>
                <input
                  type="tel"
                  id="phone"
                  value={userPhone}
                  onChange={(e) => setUserPhone(e.target.value)}
                  placeholder="Tu teléfono"
                  required
                />
              </div>
              <div className={styles.buttonsInv}>
                  <button
                    type='submit'
                    onClick={handleWhatsAppClick}
                  >
                    Enviar
                  </button>  
              </div>
            </form>
          </div>
        </Modal>
      )}

      <div className={styles.buttonsReserve}>
        <button onClick={handleReserveClick}>Reservar Boletos</button>
      </div>
    </section>
  );
}

export default ComprarBoletos;
