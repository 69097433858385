import React from 'react';
import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route, Outlet } from 'react-router-dom';
import NavBar from './Components/NavBar/navbar.js';
import Footer from './Components/Footer/footer.js';
import Inicio from './Pages/Inicio/inicio.js';
import PreguntasFrecuentes from './Pages/PreguntasFrecuentes/preguntasfrecuentes.js';
import Contacto from './Pages/Contacto/contacto.js';
import MetodoPago from './Pages/MetodoPago/metodopago.js';
import ComprarBoletos from './Pages/ComprarBoletos/comprarboletos.js';
import CarroCompras from './Pages/CarroCompras/carroCompras.js';
import Register from './Pages/Auth/register.js';
import Login from './Pages/Auth/login.js';
import Admin from './Pages/Usuarios/admin.js';
import Usuario from './Pages/Usuarios/usuario.js';
import ProtectedRoute from './ProtectedRoute.js'; 
import styles from './App.css';
import { AuthProvider } from './context/auth-context.js';
import TicketProvider from './context/ticket-context.js';
import ShopContextProvider from './context/shop-context.js';
import { UserProfileProvider } from './context/perfil-context.js';
import EmailVerify from './Components/EmailVerify/emailVerify.js';


const Layout = () => (
  <div className={styles.layout}>
    <AuthProvider>
      <UserProfileProvider>
        <TicketProvider>
          <ShopContextProvider>
            <NavBar />
            <main className={styles['main-content']}>
              <Outlet /> 
            </main>
            <Footer />
          </ShopContextProvider>
        </TicketProvider>
      </UserProfileProvider>
    </AuthProvider>
  </div>
);

const appRouter = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Layout />} >
      <Route index element={<Inicio />} />
      <Route path="/Inicio" element={<Inicio />} />
      <Route path="/PreguntasFrecuentes" element={<PreguntasFrecuentes />} />
      <Route path="/Contacto" element={<Contacto />} />
      <Route path="/MetodoCompra" element={<MetodoPago />} />
      <Route path="/ComprarBoletos" element={<ComprarBoletos />} />
      <Route path="/CarroCompras" element={<CarroCompras />} />
      <Route path="/Registro" element={<Register />} />
      <Route path="/Login" element={<Login />} />
      {/* Rutas protegidas para el perfil de usuario y administrador */}
      <Route path="/Perfil" element={<ProtectedRoute adminComponent={Admin} userComponent={Usuario} />} />
      <Route path="/PerfilAdmin" element={<ProtectedRoute adminComponent={Admin} userComponent={Usuario} />} />
      <Route path='api/usuarios/:id/verify/:token' element={<EmailVerify />}/>
      <Route path="*" element={<p>404 - Página no encontrada</p>} /> {/* Ruta para manejar páginas no encontradas */}
    </Route>
  )
);

function App() {
  return (
    <React.StrictMode>
      <RouterProvider router={appRouter} />
    </React.StrictMode>
  );
}

export default App;
