import React from 'react';
import styles from './metodopago.module.css';

function MetodoPago() {
  return (
    <section id="payment" className={styles['payment-section']}>
      <h2>¿Cómo Comprar tus Boletos?</h2>
      <h3>Instrucciones paso a paso:</h3>
      <div className={styles.step}>
        <h4>1. Selecciona los boletos:</h4>
        <p>Decide la cantidad de boletos que deseas adquirir para participar en la rifa. Puedes elegir tantos como desees, y cada boleto aumenta tus posibilidades de ganar el MINI Cooper S Hot Chilli 2013.</p>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.step}>
        <h4>2. Revisa la disponibilidad:</h4>
        <p>Verifica si los boletos que deseas están disponibles. Asegúrate de mencionar tu nombre completo, número de teléfono y correo electrónico para que podamos contactar contigo en caso de ganar. Esto nos ayudará a mantener una comunicación efectiva.</p>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.step}>
        <h4>3. Realiza la transferencia:</h4>
        <p>Haz el pago de los boletos a través de transferencia bancaria. Asegúrate de seguir las instrucciones para garantizar que tu pago sea procesado correctamente.</p>
      <div className={styles.bankDetails}>
        <h5>Cuentas Bancarias:</h5>
        <ul>
          <li>
            <strong>Banco:</strong> Banbajio <br />
            <strong>CLABE:</strong> 030227900037561216 <br />
            <strong>Número de Tarjeta:</strong> 4210 0300 5443 2138 <br />
            <strong>Nombre del Beneficiario:</strong> Lucky Garage 
          </li>
          <li>
            <strong>Banco:</strong> Banorte <br />
            <strong>CLABE:</strong> 072227012566195902 <br />
            <strong>Número de Tarjeta:</strong> 4189 1430 9601 9321 <br />
            <strong>Nombre del Beneficiario:</strong> Lucky Garage
          </li>
        </ul>
      </div>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.step}>
        <h4>4. Envíanos el comprobante:</h4>
        <p>Después de completar la transferencia, envíanos una foto de tu comprobante de pago. Esto nos permitirá verificar tu compra y enviarte un mensaje de confirmación con tus números de boleto por <a href="https://wa.me/523335027497" target="_blank" rel="noopener noreferrer">WhatsApp</a>.</p>
      </div>
    </section>
  );
}

export default MetodoPago;
