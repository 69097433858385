import React, { useState, useEffect } from 'react';
import styles from '../Inicio/inicio.module.css';

const CountdownUnit = ({ value, label }) => (
  <div className={styles.countdownUnit}>
    <h2>{value}</h2>
    <span>{label}</span>
  </div>
);

// Function to calculate remaining time
function calculateTimeLeft() {
  const targetDate = new Date("2025-01-28T19:59:59");
  const now = new Date();
  const difference = targetDate - now;
  let timeLeft = {};

  if (difference > 0) {
    timeLeft = {
      dias: Math.floor(difference / (1000 * 60 * 60 * 24)),
      horas: Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutos: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
      segundos: Math.floor((difference % (1000 * 60)) / 1000)
    };
  } else {
    // Countdown has ended
    timeLeft = { dias: 0, horas: 0, minutos: 0, segundos: 0 };
  }

  return timeLeft;
}

function Countdown() {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    // Update the timeLeft state every second
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, []);

  const timerComponents = Object.keys(timeLeft).map(interval => {
    let label;
    switch (interval) {
      case 'dias':
        label = timeLeft[interval] === 1 ? 'día' : 'días';
        break;
      case 'horas':
        label = timeLeft[interval] === 1 ? 'hora' : 'horas';
        break;
      case 'minutos':
        label = timeLeft[interval] === 1 ? 'minuto' : 'minutos';
        break;
      case 'segundos':
        label = timeLeft[interval] === 1 ? 'segundo' : 'segundos';
        break;
      default:
        label = interval;
    }

    // Always display units, even if their value is 0
    return <CountdownUnit key={interval} value={timeLeft[interval]} label={label} />;
  });

  return (
    <div className={styles.countdown}>
      {/* Display countdown units, even if all values are 0 */}
      {timerComponents.length ? timerComponents : <span>¡El sorteo ha comenzado!</span>}
    </div>
  );
}

export default Countdown;
