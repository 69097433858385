import React from 'react';
import styles from './contacto.module.css';
import { FaFacebook, FaInstagram, FaTiktok } from 'react-icons/fa';

function Contacto() {
  return (
    <div className={styles.body}>
      <section id="contact" className={styles['contact-section']}>
        <h2>¿Tienes Dudas? Contáctanos</h2>
        
        {/* Social Media Links */}
        <h3>Redes sociales</h3>
        <p>Síguenos en nuestras redes sociales para estar al día con todas las actualizaciones sobre la rifa.</p>
        <div className={styles['social-media']}>
          <a href="https://www.facebook.com/profile.php?id=61567974946223" target="_blank" rel="noopener noreferrer">
            <p><FaFacebook className={styles.icon} /> Facebook: @Lucky-Garage-Mexico </p>
          </a>
          <a href="https://www.instagram.com/luckygarage2024/" target="_blank" rel="noopener noreferrer">
            <p><FaInstagram className={styles.icon} /> Instagram: @luckygarage2024</p>
          </a>
          <a href="https://www.tiktok.com/@luckygarage270 " target="_blank" rel="noopener noreferrer">
            <p><FaTiktok className={styles.icon} /> TikTok: @luckygarage270</p>
          </a>
        </div>

        {/* Additional Info */}
        <h3>Información adicional:</h3>
        <p>Para cualquier otra consulta, puedes escribirnos a <a
          href="mailto:luckygarageraffle@gmail.com?subject=Rifa%20Inquiry&body=Hola%2C%20Escribe%20tu%20texto."
          target="_blank"
          rel="noopener noreferrer">luckygarageraffle@gmail.com </a> 
          o al <a href="https://wa.me/523335027497?text=Quisiera%20saber%20más%20sobre%20la%20rifa." target="_blank" rel="noopener noreferrer">+52 (33) 3502 7497</a> por medio de WhatsApp.
        </p>
      </section>
    </div>
  );
}

export default Contacto;
