import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { ShoppingCart } from 'phosphor-react';
import logo from '../../assets/LOGO_SF_sinmargen.svg';
import styles from './navbar.module.css';
import { AuthContext } from '../../context/auth-context.js';

function NavBar() {
    const [isActive, setIsActive] = useState(false);
    const { user, logout } = useContext(AuthContext);
    const navigate = useNavigate();

    const toggleActiveClass = () => {
        setIsActive(!isActive);
    };

    const removeActive = () => {
        setIsActive(false);
    };

    const handleLogout = () => {
        logout();
        removeActive();
        navigate('/'); // Redirigir a la página de inicio al cerrar sesión
    };

    return (
        <div className={styles.App}>
            <nav className={`${styles.navbar}`}>
                <ul className={`${styles.navMenu} ${isActive ? styles.active : ''}`}>
                    <li key="inicio" onClick={removeActive}>
                        <Link to="/Inicio">Inicio</Link>
                    </li>
                    <li key="faq" onClick={removeActive}>
                        <Link to="/PreguntasFrecuentes">Preguntas Frecuentes</Link>
                    </li>
                    <li key="contacto" onClick={removeActive}>
                        <Link to="/Contacto">Contacto</Link>
                    </li>
                    <li key="metodosPago" onClick={removeActive}>
                        <Link to="/MetodoCompra">Método de Compra</Link>
                    </li>
                    <li key="comprarBoletos" onClick={removeActive}>
                        <Link to="/ComprarBoletos">Reservar Boletos</Link>
                    </li>
                    <li key="carroCompras" onClick={removeActive}>
                        <Link to="/CarroCompras">
                            <ShoppingCart size={20} />
                        </Link>
                    </li>
                </ul>

                <Link to="/Inicio" className={`${styles.logoContainer}`}>
                    <img src={logo} alt="Lucky Garage Logo" className={styles.logoImage} />
                </Link>

                <div className={styles.userActions}>
                    {user ? (
                        <>
                            <Link to="/Perfil" style={{ cursor: 'pointer' }}>
                                Hola, {user.nombre}
                            </Link>
                            <Link onClick={handleLogout} style={{ cursor: 'pointer' }}>
                                Cerrar Sesión
                            </Link>
                        </>
                    ) : (
                        <>
                            <Link to="/Login" key="inicioSesion" onClick={removeActive}>Iniciar Sesión</Link>
                            <Link to="/Registro" key="registrar" onClick={removeActive}>Registrarse</Link>
                        </>
                    )}
                </div>

                <div className={`${styles.hamburger} ${isActive ? styles.active : ''}`} onClick={toggleActiveClass}>
                    <span className={`${styles.bar}`}></span>
                    <span className={`${styles.bar}`}></span>
                    <span className={`${styles.bar}`}></span>
                </div>
            </nav>
        </div>
    );
}

export default NavBar;
