import styles from './emailVerify.module.css';
import { useState, useEffect, Fragment } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const EmailVerify = () => {
    const [validUrl, setValidUrl] = useState(false);
    const [verificationMessage, setVerificationMessage] = useState('');
    const param = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const verifyEmailUrl = async () => {
            try {
                // URL for the backend API
                const url = `https://luckygarage-api.onrender.com/api/usuarios/${param.id}/verify/${param.token}`;
                
                // Make the GET request to verify the email
                const { data } = await axios.get(url);
                
                // Check if the backend returned an 'activo' status meaning successful verification
                if (data.status === 'activo') {
                    setValidUrl(true);
                    setVerificationMessage('Correo verificado exitosamente.');
                    
                    // Redirect to login after 3 seconds
                    navigate('/Login');
                } else {
                    setValidUrl(false);
                    setVerificationMessage('El enlace no es válido o la cuenta ya está verificada.');
                }
            } catch (error) {
                console.error('Error verifying email:', error);
                setValidUrl(false);

                // Handle error responses from backend
                if (error.response) {
                    if (error.response.status === 404) {
                        setVerificationMessage('El enlace no fue encontrado. Asegúrate de que es correcto.');
                    } else if (error.response.status === 500) {
                        setVerificationMessage('Error en el servidor. Por favor, inténtalo más tarde.');
                    } else {
                        setVerificationMessage('Error desconocido. Por favor, revisa el enlace o intenta más tarde.');
                    }
                } else if (error.request) {
                    setVerificationMessage('No se pudo conectar al servidor. Verifica tu conexión a internet.');
                } else {
                    setVerificationMessage('Error al intentar verificar el correo. Inténtalo de nuevo más tarde.');
                }
            }
        };

        // Trigger email verification when component loads
        verifyEmailUrl();
    }, [param, navigate]);

    return (
        <Fragment>
            {validUrl ? (
                <div className={styles.container}>
                    <h1>{verificationMessage}</h1>
                    <Link to="/Login">
                        <button>Iniciar Sesión</button>
                    </Link>
                </div>
            ) : (
                <div className={styles.container}>
                    <h1>404 - {verificationMessage}</h1>
                </div>
            )}
        </Fragment>
    );
};

export default EmailVerify;
